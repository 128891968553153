/* eslint-disable jsx-a11y/alt-text */
import Footer from "../Components/Footer";
import { PageHero } from "../Components/generic/PageHero";
import {
  checkstar,
  eye,
  farm,
  farmer,
  farmers,
  flag,
  photos,
  product,
  school,
  thumb,
  trophy,
  values,
  x,
} from "../Constants/imports";
import { Link } from "react-router-dom";

const metric = [
  {
    img: product,
    count: 1021,
    title: "Agriculture Product",
  },
  {
    img: trophy,
    count: 1560,
    title: "Project Completed",
  },
  {
    img: checkstar,
    count: 268,
    title: "Satisfied Clients",
  },
  {
    img: thumb,
    count: 15,
    title: "Expert Farmers",
  },
];

export function Metric(prop) {
  return (
    <div className="col-lg-3 col-md-6 dcol text-center">
      <img src={prop.item.img} style={{ width: "70px" }} />
      <h1>{prop.item.count}</h1>
      <span>{prop.item.title}</span>
    </div>
  );
}

export default function About() {
  return (
    <>
      <PageHero title="About Sa’I Anwara ’I’ Jumai Consultaire Limited (SCL)" />
      <div className="section">
        <div className="row">
          <div className="col-lg-8 offset-lg-2 text-center">
            Experience has taught us that the best way to acquire knowledge is
            "learning by doing". So, our Farm-Farmer School, located in the
            heart of the over 100-hectare farm affords us the environment to
            keep trainees in situ while they learn and practice the principles
            of regenerative agriculture for effective extension service delivery
            and hand-holding of our regenerative- agriculture-adopting
            communities. Our Farm-Farmer School is equipped with boarding
            facilities, a central refectory, demo plots and recreation
            facilities all sustainably solar powered. We offer trainings for
            farmers, institutions, policy makers, trainers and extension agents.
          </div>
        </div>
      </div>
      <div>
        <img src={photos} style={{ width: "100%" }} />
      </div>
      <div className="pt-lg-5 pb-lg-5"></div>
      <div className="section">
        <div className="rwnd-row">
          <div className="rwnd-col m12 l3 w3-container p-3">
            <div className="scl-card scl-vision ">
              <img src={eye} />
              <h4>Vision</h4>
              <div>
                To realize the lost glory of Agriculture and its imperative
                importance in the sustenance of the Nigerian People.
              </div>
            </div>
          </div>
          <div className="rwnd-col m12 l6 w3-container p-3">
            <div className="scl-card scl-mission ">
              <img src={flag} />
              <h4>Mission</h4>
              <div>
                To produce premium products and services with the aid of modern
                concepts and technology, thus making Agro-Allied Business a
                major source of economic empowerment and livelihood to
                Nigerians.
              </div>
            </div>
          </div>
          <div className="rwnd-col m12 l3 w3-container p-3">
            <div className="scl-card scl-values ">
              <img src={values} />
              <h4>4i Values</h4>
              <div>Integrity, Intensity, Innovation, and Involvement</div>
            </div>
          </div>
        </div>
      </div>

      <div className="metric ">
        <div className=" row container-fluid ">
          {metric.map((item, key) => (
            <Metric item={item} key={key} />
          ))}
        </div>
      </div>

      <div className="section">
        <div className=" row">
          <div className="col-lg-9">
            <p className="uppercase text-myorange font-bold text-[16px]">
              COACHING AND MENTORSHIP
            </p>
            <p className="capitalize linehight text-darkgreen text-left font-bold text-[28px] pt-4">
              We also have a team of dedicated professionals who provide
              coaching and mentoring in agri-businesses for young entrepreneurs
              and new generation of farmers.
            </p>
            <p className="pb-4 pt-4">
              SCL also train youths and women on entrepreneurship in general and
              livestock based vocational enterprise development specifically,
              thereby building local capacities in sustainable and profitable
              fish, poultry and livestock farming ventures as well as in crop
              production, processing and marketing of agro-produce.
            </p>
          </div>
          <div className="col-lg-4">
            <img className="w-full" src={school} />
          </div>
          <div className="col-lg-4">
            <img className="w-full" src={farmer} />
          </div>
          <div className="col-lg-4">
            <img className="w-full" src={farm} />
          </div>
        </div>
      </div>
      <div className="section">
        <div className=" row">
          <div className="col-lg-6">
            <img className="w-full" src={farmers} />
          </div>
          <div className="col-lg-6">
            <p className="uppercase text-myorange font-bold text-[16px]">
              EDUCATING FARMERS ON REGENERATIVE ENERGY
            </p>
            <p className="capitalize linehight text-darkgreen text-left font-bold text-[28px] pt-3">
              Join Us On Our Journey
            </p>
            <p className="pb-4 pt-4">
              At SCL Farms, we believe in a future where agriculture sustains
              communities, supports livelihoods, and protects the planet.
              Together, let's cultivate a greener, healthier, and more
              prosperous world. Join us on this incredible journey as we sow the
              seeds of change and harvest a bountiful future. Get in touch with
              us today and explore how we can collaborate for a more sustainable
              and prosperous agricultural future. Thank you for visiting SCL
              Farms! Sa'l Anwara 'I' Jumai Consultaire Limited (SCL) Farms Your
              Trusted Partner in Agriculture and Agro-Allied Solutions. SCL
              Farms - Nurturing Growth, Harvesting Success.
            </p>
            <Link to="contact">
              <button className="btn btn-primary">Get In Touch</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="pt-lg-5 pb-lg-5"></div>
      
    </>
  );
}
