/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  sclwhitelogo,
  instagram,
  linkedin,
  dlocation,
  x,
  call,
  email,
  facebook,
} from "../Constants/imports";
import { navinks } from "../Constants/data";

function Footer() {
  const location = useLocation();
  var pathname = location.pathname;

  return (
    <div className="footer">
      <div className="pt-lg-5 px-lg-5 pe-lg-5 ">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26272.22213701595!2d6.927121538983064!3d8.819545201164873!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e8da07256a94b%3A0x884f2a30eaaa2a88!2sSCL%20Agroindustrial%20Farm!5e0!3m2!1sen!2sng!4v1713950653834!5m2!1sen!2sng"
          width="100%"
          height="350"
          style={{ overflow: "hidden", borderRadius: "5px", border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div className="container section">
        <div className="row">
          <div className="col-lg-3 text-white pb-5">
            <img src={sclwhitelogo} style={{ width: "200px" }} />
            <div>
              <span>
                At SCL, we are passionate about transforming the agricultural
                landscape and contributing to sustainable development.
              </span>
              <div className="social-icon">
                <hr />
                <img src={facebook} style={{ height: "50px" }} />
                <img src={instagram} style={{ height: "50px" }} />
                <img src={x} style={{ height: "50px" }} />
                <img src={linkedin} style={{ height: "50px" }} />
              </div>
              <span style={{ fontSize: "12px" }}>
                {" "}
                Copyrights 2016 - 2024 SCL. All right Reserved
              </span>
            </div>
          </div>
          <div className="col-lg-2">
            <p className="uppercase text-myorange font-bold text-[16px]">
              SERVICES
            </p>
            <ul className="footerlink pb-5">
              <li>
                <a href="/aboutus">Apiculture</a>
              </li>
              <li>
                <a href="/ourteam">Warehousing</a>
              </li>
              <li>
                <a href="/projects">Green House Farming</a>
              </li>
            </ul>
          </div>

          <div className="col-lg-3">
            <p className="uppercase text-myorange font-bold text-[16px] ">
              CONTACT
            </p>
            <div className="footerContact">
              <div className="row">
                <div className="col-2 text-center p-0 ">
                  <img src={email} />
                </div>
                <div className="col-9 p-0">
                  <span> info@sclng.com</span>
                  <br />
                  <span> sales@sclng.com</span>
                </div>
              </div>

              <div className="row pt-4">
                <div className="col-2 text-center p-0 ">
                  <img src={call} />
                </div>
                <div className="col-9 p-0">
                  <span> SCL HO MAITAMA</span>
                  <br />
                  <b> 234 (0) 911 731 2324</b>
                  <hr style={{ width: "50px" }} className="mt-2 mb-2" />
                  <span> SCL Operation Office Kwali</span>
                  <br />
                  <b> 234 (0) 911 742 7795</b>
                </div>
              </div>

              <div className="row pt-4">
                <div className="col-2 text-center p-0 ">
                  <img src={dlocation} />
                </div>
                <div className="col-9 p-0">
                  SCL Farm, Dama-kusa Village, Kwali Area Counsil, Abuja
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <p className="uppercase text-myorange  font-bold text-[16px]">
              SUBSCRIBE TO OUR NEWSLETTER
            </p>
            <div className="newsletter">
              The latest news, articles, and resources, sent into your inbox
              weekly.
              <form>
                <input
                  type="email"
                  className="formcontrol "
                  placeholder="Enter your email address"
                  required
                />
                <br />
                <button className="btn btn-primary" type="submit">
                  Subscribe
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
