import { useState } from "react";
import { Loader } from "../Components/generic/Loader";

const Blog = () => {


    var [showloader, setshowloader] = useState(true)
    function handleIframeLoad() {

        setshowloader(false)
    }

    return (<>

        {showloader && <Loader />}

            <iframe onLoad={handleIframeLoad} src="https://blog.sclng.com" className={`w-full   h-[100vh]`} title="SCL Blog"></iframe>    


</>)
}

export { Blog }