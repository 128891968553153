import { carosalimg } from "../../Constants/imports"
import Learnmorebtn from "../generic/Learnmorebtn"
import Marquee from "react-fast-marquee";


const Hero =()=>{



    return(<div className="w-full flex-col flex justify-center items-center "> 
    <div className="  w-[60%] flex justify-center items-center flex-col">


<p className="text-darkgreen font-bold text-[64px] worksans text-center ">Embracing the Future of Agriculture</p>
<p className="text-black text-[16px] inter "

>Through Regenerative Agriculture we are Creating Future Food Systems</p>


<Learnmorebtn/>

</div>

<Marquee className="w-full mt-10">
<img  src={carosalimg}/>

</Marquee>
</div>)
} 


export {Hero}