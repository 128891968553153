import { PageHero } from "../Components/generic/PageHero"

const Contact =()=>{



    return( <>
    <PageHero title='Contact Us'/></> )


}

export{Contact}