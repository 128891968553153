const PageHero = (props) => {
  return (
    <div className="page-head flex justify-center items-center">
      <h1 className="  w-[60%] text-white font-bold  text-center">
        {props.title}
      </h1>
    </div>
  );
};

export { PageHero };
