import { Link } from "react-router-dom";
import { PageHero } from "../Components/generic/PageHero";
import {
  halfKBLogo,
  halfSCLLogo,
  prAcresal,
  prJuriya,
  prKoboweb,
} from "../Constants/imports";

const Projects = () => {
  const data = [
    {
      id: 1,
      project: "Project Juriya",
      desc: "Lorem ipsum dolor sit amet consectetur. Nulla at at ornare scelerisque non. Lorem ipsum dolor sit amet consectetur. Nulla at at ornare scelerisque non.",
      image: prJuriya,
      bgColor: "#D2FCDA",
      bottomImg: halfSCLLogo,
      links: [
        {
          title: "Year 1",
          path: "/projects",
          color: "#FFFFFF",
          bgColor: "#007D62",
          borderColor: "#007D62",
        },
        {
          title: "Year 2",
          path: "/projects",
          color: "#007D62",
          bgColor: "rgba(0, 0, 0, 0)",
          borderColor: "#007D62",
        },
      ],
    },
    {
      id: 2,
      project: "ACReSAL Adamawa",
      desc: "We're working hard to bring you something awesome. Stay tuned!",
      image: prAcresal,
      bgColor: "#FFF0D4",
      bottomImg: halfSCLLogo,
      links: [
        {
          title: "Coming Soon!",
          path: "/projects",
          color: "#FFFFFF",
          bgColor: "#737978",
          borderColor: "#737978",
        },
      ],
    },
    {
      id: 3,
      project: "KoboGreentech Solution",
      desc: "Lorem ipsum dolor sit amet consectetur. Nulla at at ornare scelerisque non. Lorem ipsum dolor sit amet consectetur. Nulla at at ornare scelerisque non.",
      image: prKoboweb,
      bgColor: "#E4EBFF",
      bottomImg: halfKBLogo,
      links: [
        {
          title: "Year 1",
          path: "/projects",
          color: "#FFFFFF",
          bgColor: "#007D62",
          borderColor: "#007D62",
        },
      ],
    },
  ];
  return (
    <>
      <PageHero title="SCL Projects" />
      {/* Project Listings */}
      <div className="px-14 py-10">
        {data.map((item, index) => (
          <ProjectCard item={item} key={index} />
        ))}
      </div>


    </>
  );
};

export { Projects };

const ProjectCard = ({ item }) => {
  const { project, desc, image, bgColor, bottomImg, links } = item;
  return (
    <div
      style={{ backgroundColor: bgColor }}
      className={`w-full my-5 rounded-[8px] p-4 relative`}
    >
      {/* Image and Desc */}
      <div className="lg:flex items-start">
        {/* Image */}
        <img
          alt="Project"
          src={image}
          className="h-[297px] w-[572px] rounded-[4px]"
        />
        {/* Desc */}
        <div className="ml-3">
          <h1 className="text-[#033529] text-[28px] font-bold">{project}</h1>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "24px",
              textWrap: "wrap",
            }}
            className="my-2"
          >
            {desc}
          </p>
          {/* Links */}
          <div className="my-3 flex items-center gap-5">
            {links?.map((link, index) => (
              <Link
                key={index}
                to={link?.path}
                style={{
                  borderRadius: "4px",
                  padding: "18px 32px 18px 32px",
                  borderWidth: 1,
                  borderColor: link?.borderColor,
                  backgroundColor: link?.bgColor,
                  color: link?.color,
                }}
              >
                {link?.title}
              </Link>
            ))}
          </div>
        </div>
      </div>

      {/* Bottom Image */}
      <img alt="Logo" className="absolute right-0 bottom-0" src={bottomImg} />
    </div>
  );
};

