import Footer from "../Components/Footer";
import { Hero } from "../Components/Home/Hero";
import { Offeritem } from "../Components/Home/Offeritem";
import { Whyitem } from "../Components/Home/Whyitem";
import { offeritems, whyitems } from "../Constants/data";
import {
  educatefarmer,
  productqualty,
  whoweare1,
  whyregagric,
} from "../Constants/imports";

const Home = () => {
  return (
    <>
      <Hero />
      <section className=" grid grid-cols-2 bg-white p-20 px-[95px] gap-10">
        <div>
          <p className="uppercase text-myorange font-bold text-[16px]">
            Who are we
          </p>
          <p className="capitalize linehight text-darkgreen text-left font-bold text-[28px]">
            An Agro-Allied Company with investment and a developmental focus on
            “people, crops, livestock and environment”.
          </p>
          <p>
            SCL is a fully indigenous limited liability Agro-Allied company
            registered in 2010. The company was incorporated to carry out
            variety of Agro-Allied businesses and services. Our major strength
            is our highly trained technical staff base, led by a board and
            management team with over 150 years cumulative corporate management
            experience. At SCL, we are passionate about transforming the
            agricultural landscape and contributing to sustainable development.
            With a deep-rooted commitment to excellence, innovation, and
            community welfare, we stand as a reliable partner in agriculture,
            empowering farmers, and enriching lives.
          </p>
        </div>
        <div>
          <img className="w-full" src={whoweare1} />
        </div>

        <div>
          <img className="w-full" src={productqualty} />
        </div>

        <div className=" flex flex-col justify-center  ">
          <p className="uppercase text-myorange font-bold text-[16px]">
            PRODUCT QUALITY
          </p>
          <p className="capitalize linehight text-darkgreen text-left font-bold text-[28px]">
            We take great pride in the quality of our agricultural produce and
            agro-allied products.
          </p>
          <p>
            From fresh fruits and vegetables to processed goods, each item
            undergoes stringent quality checks to ensure it meets our high
            standards. Our commitment to excellence has earned us the trust of
            numerous customers, both locally and internationally.{" "}
          </p>
        </div>
      </section>

      <section className=" bg-white p-20 px-[95px] ">
        <div className="grid grid-cols-3 gap-6">
          {offeritems.slice(0, 3).map((item, key) => (
            <Offeritem item={item} key={key} />
          ))}
        </div>

        <div className="grid grid-cols-2 gap-6 mt-8">
          {offeritems.slice(3, 6).map((item, key) => (
            <Offeritem item={item} key={key} />
          ))}
        </div>
      </section>
      <section className="p-20 px-[95px] bg-white">
        <div className="w-[48%]  ">
          <p className="uppercase text-myorange font-bold text-[16px]">
            Why SCL ?
          </p>
          <p className="capitalize linehight text-darkgreen text-left font-bold text-[28px]">
            Why Choose SCL Agro Allied Farm
          </p>
          <p>
            We believe in threading lightly on the earth, complete control over
            our production process, while being rooted in community values. We
            are not just a business, it’s our Passion.{" "}
          </p>
        </div>
        <div className="grid grid-cols-4 gap-6 mt-10">
          {whyitems.map((item, key) => (
            <Whyitem item={item} key={key} />
          ))}
        </div>
      </section>

      <section className=" grid grid-cols-2 bg-white p-20 px-[95px] gap-10">
        <div>
          <p className="uppercase text-myorange font-bold text-[16px]">
            Why Regenerative Agriculture?
          </p>
          <p className="capitalize linehight text-darkgreen text-left font-bold text-[28px]">
            A personal responsibility to support regenerative agriculture for a
            resilient future.
          </p>
          <p>
            It's our solution to combat soil degradation, climate change, and
            promote sustainable food production. By adopting regenerative
            practices, we can restore ecosystem health, sequester carbon,
            conserve water, and foster biodiversity.{" "}
          </p>
        </div>
        <div>
          <img className="w-full" src={whyregagric} />
        </div>

        <div>
          <img className="w-full" src={educatefarmer} />
        </div>

        <div className=" flex flex-col justify-center  ">
          <p className="uppercase text-myorange font-bold text-[16px]">
            Educating Farmers on Regenerative Energy
          </p>
          <p className="capitalize linehight text-darkgreen text-left font-bold text-[28px]">
            Regenerative agricultural practice is a knowledge-based operation
            requiring a knowledgeable worker.
          </p>
          <p>
            Experience has taught us that the best way to acquire knowledge is
            “learning by doing”. So, our Farm-Farmer School, located in the
            heart of the over 100-hectare farm affords us the environment to
            keep trainees in situ while they learn and practice the principles
            of regenerative agriculture for effective extension service delivery
            and hand-holding of our regenerative-agriculture-adopting
            communities. Our Farm-Farmer School is equipped with boarding
            facilities, a central refectory, demo plots and recreation
            facilities all sustainably solar powered. We offer trainings for
            farmers, institutions, policy makers, trainers and extension agents.
          </p>
        </div>
      </section>

      <div className="pt-lg-5 pb-lg-5"></div>
    
    </>
  );
};

export { Home };
