 import { Home } from './Pages/Home';
import logo from './logo.svg';
import "./frameworks.css";
import "./output.css";
import "./custom.css";
import "animate.css/animate.css";
 
 
import './output.css';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from './Pages/About';
import NavBar from './Components/NavBar';
import { Products } from './Pages/Products';
import { Gallery } from './Pages/Gallery';
import { Projects } from './Pages/Projects';
import { Academy } from './Pages/Academy';
import { Contact } from './Pages/Contact';
import { Blog } from './Pages/Blog';
import Footer from './Components/Footer';



function App() {
  return (
   <>
           
      <Router> 
      <NavBar/>
        <Routes>
          <Route path="/"   element={<Home/>} />
          <Route path="/about-us"   element={<About/>} />
          <Route path="/products"   element={<Products/>} />
          <Route path="/gallery"   element={<Gallery/>} />
          <Route path="/projects"   element={<Projects/>} />
          <Route path="/academy"   element={<Academy/>} />
          <Route path="/blog"   element={<Blog/>} />
          <Route path="/contact"   element={<Contact/>} />
        </Routes>
        <Footer />
      </Router>

      </>
 


 
  );
}

export default App;
