import { Link } from "react-router-dom";
import { PageHero } from "../Components/generic/PageHero";
import { gOne, gTwo, gThree, gFour, gFive } from "../Constants/imports";

const Academy = () => {
  const images = [gOne, gTwo, gThree, gFour, gFive];
  return (
    <>
      <PageHero title="SCL Academy" />
      <div className="section">
        <div className="row">
          <div className="col-lg-8 offset-lg-2 text-center">
            <p>
              About Sa’I Anwara ’I’ Jumai Consultaire Limited (SCL) Regenerative
              agricultural practice is a knowledge-based operation requiring a
              knowledgeable worker. Experience has taught us that the best way
              to acquire knowledge is “learning by doing”. So, our Farm-Farmer
              School, located in the heart of the over 100-hectare farm affords
              us the environment to keep trainees in situ while they learn and
              practice the principles of regenerative agriculture for effective
              extension service delivery and hand-holding of our
              regenerative-agriculture-adopting communities. Our Farm-Farmer
              School is equipped with boarding facilities, a central refectory,
              demo plots and recreation facilities all sustainably solar
              powered. We offer trainings for farmers, institutions, policy
              makers, trainers and extension agents.
            </p>
            <div className="w-full flex justify-center items-center gap-6 my-5">
              <Link
                to={"/academy"}
                className="bg-[#007D62] py-3 px-8 rounded-sm text-sm font-medium text-white"
              >
                Register Here
              </Link>
              <Link
                to={"/academy"}
                className="border-[1px] bg-white border-[#007D62] py-3 px-8 rounded-sm text-sm font-medium text-[#007D62] hover:text-[#007D62]"
              >
                Book a Tour
              </Link>
            </div>
          </div>
        </div>
        {/* Photo Gallery */}
        <div className="w-full flex justify-between">
          {images.map((item, index) => (
            <img
              alt="Academy"
              src={item}
              key={index}
              className={`w-[18%] ${index % 2 === 0 ? "mt-4" : "mb-4"}`}
            />
          ))}
        </div>
      </div>
       
    </>
  );
};
export { Academy };
