import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { navlogo } from "../Constants/imports";
import { navinks } from "../Constants/data";

function NavBar() {
  const location = useLocation();
  var pathname = location.pathname;
  console.log(pathname);
  return (
    <div className=" px-4 flex justify-between items-center rwnd-display-container scl-nav rwnd-border-bottom">
      <img src={navlogo} />
      <div className="flex ">
        {navinks.map((item) => (
          <Link to={item.path}>
            <p
              className={`  ml-4 cursor-pointer  ${
                pathname === item.path
                  ? "text-darkgreen font-bold"
                  : "text-black font-medium "
              }  text-[14px] `}
            >
              {item.title}
            </p>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default NavBar;
