import { PageHero } from "../Components/generic/PageHero"

const Gallery=()=>{



    return(<>
    <PageHero title='Masterpieces in Action: Showcasing Our Journey'/>
    </>) 
}

export {Gallery}