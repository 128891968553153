const Offeritem =(props)=>{


    return(<div className="rounded-xl bg-skyblue">

<img className= 'rounded-t-xl ' src={props.item.img} />
<div className="p-4">
<p className="text-darkgreen  font-bold text-[22px]"> {props.item.title}</p>
<p className="text-black font-light text-[16px]"> {props.item.desc}</p>
</div>

    </div>)
}



export {Offeritem}