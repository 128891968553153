import { scllogo } from "../../Constants/imports"

const Loader =()=>{



    return(<div className="flex bg-white justify-center absolute items-center w-full h-[100vh]">
    <img  className='animate-ping ' width='60px' src={scllogo} />
    </div>)

}
    
    export {Loader}