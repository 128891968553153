import { agritourism, agroprocessing, communityD, ecofriendly, freshorgproduce, integrityD, livestockrearing, passionD, sustainabilityD } from "./imports";

const offeritems=[
    
    {
    title:'Fresh Organic Produce',
    img:freshorgproduce,
    desc:'Delivering the freshest and healthiest organic produce to your table. Our farm is a haven for naturally grown fruits, vegetables, and herbs, free from harmful chemicals and additives.'},
    {
        title:'Livestock Rearing',
        img:livestockrearing,
        desc:'Experience the richness of farm-fresh dairy and ethically raised poultry from our livestock operations. We prioritize animal welfare, ensuring that our livestock is healthy, happy, and sustainably raised.'}
        ,
        {
            title:'Eco-Friendly Initiatives',
            img:ecofriendly,
            desc:'QSCL Agro Allied Farm is committed to environmental stewardship. We implement eco-friendly practices, sustainable packaging, and waste reduction efforts to protect our planet for future generations.'}
            ,
            {
                title:'Agri-Tourism and Education',
                img:agritourism,
                desc:'Immerse yourself in the world of agriculture through our agri-tourism experiences and educational programs. Learn about sustainable farming, interact with farm animals, and reconnect with nature.'},
                {
                    title:'Agro Processing',
                    img:agroprocessing,
                    desc:'Through our state-of-the-art agro processing facility, we add value to raw agricultural products, offering a range of premium processed goods while maintaining their nutritional integrity.'}


]

const whyitems=[


    {
        title:'Farm-to-Table Integrity',
        img:integrityD,
        desc:'With complete control over the production process, we ensure the highest standards of quality, freshness, and traceability, from our fields to your table.'
    },

    {
        title:'Sustainability',
        img:sustainabilityD,
        desc:'We believe in treading lightly on the earth. Our regenerative practices and resource-efficient methods reflect our commitment to a sustainable future.'
    },
    {
        title:'Community Focus',
        img:communityD,
        desc:'SCL Agro Allied Farm is rooted in community values. We support local economies, empower farmers, and actively participate in rural development initiatives.'
    },
    {
        title:'Passion for Agriculture',
        img:passionD,
        desc:"Agriculture is not just a business; it's our passion. Our love for the land drives us to continually improve and innovate for a thriving agro-allied sector."
    }
]


const navinks =[

    {title:'Home',
path:'/'}
,
{title:'About us',
path:'/about-us'},

{title:'Products',
path:'/products'},

{title:'Galery',
path:'/gallery'}
,
{title:'Blog Post',
path:'/blog'},
,


{title:'Projects',
path:'/projects'}
,
{title:'SCL Academy',

path:'/academy'}
,
{title:'Contact Us',
path:'/contact'}


]
export {offeritems,whyitems, navinks}