import { PageHero } from "../Components/generic/PageHero"

const Products =()=>{


return(<>
 <PageHero title='Our Products'/>
    
</>)


}

export{Products}